import { Stringifiable } from 'query-string'

import { useAppSelector } from '@hooks/redux'

import { JobReducerState } from './types'

type QueryKeys = keyof JobReducerState['jobListingQueries']

const MAP_JOB_STATUS = {
  active: ['Active'],
  inactive: ['InActive', 'Pending'],
  expired: ['Expired'],
  all: [],
}

const transformStateToQueryParams = (
  jobListingQueries: JobReducerState['jobListingQueries']
) => {
  const { tab, ...restQueries } = jobListingQueries

  const result: Record<PropertyKey, Stringifiable> = {}

  Object.keys(restQueries).forEach((key) => {
    const value = restQueries[key as Exclude<QueryKeys, 'tab'>]

    if (Array.isArray(value)) {
      result[key] = JSON.stringify(value)
    } else {
      result[key] = value as string | undefined
    }
  })

  result.status = JSON.stringify(MAP_JOB_STATUS[tab])

  return result
}

export const useJobListingStateQueries = () => {
  const queriesState = useAppSelector(
    (state) => state.jobReducer.jobListingQueries
  )

  return transformStateToQueryParams(queriesState)
}
