import React from 'react'

import { appActions } from '@features/app/reducers'

import Button from '@components/button'
import Modal from '@components/modal/modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import MODAL_NAME from '@constants/modal-name'

const KycInReviewNotifyModal = () => {
  const appModalName = useAppSelector((state) => state.appReducer.modalName)
  const open = appModalName === MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL
  const dispatch = useAppDispatch()

  return (
    <Modal
      isCloseButtonHidden
      open={open}
      onClose={() => dispatch(appActions.removeModal())}
    >
      <div className="max-w-[400px]">
        <p className="mb-[24px] text-center">
          Kami sedang meninjau informasi Anda. Tunggu kabar dari kami dalam 1x24
          jam. Verifikasi Senin-Jumat 09.00-17.00, kecuali hari libur.
        </p>
        <div className="flex items-center gap-x-[16px]">
          <Button
            type="button"
            appearance="primary"
            fullWidth
            onClick={() => dispatch(appActions.removeModal())}
          >
            Mengerti
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default KycInReviewNotifyModal
