/* eslint-disable react/destructuring-assignment */
import { Stringifiable } from 'query-string'
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'

import { JOB_LISTING_URL } from '@constants/url'

import customFetcher from '@utils/custom-fetcher'

import { Metadata } from '@custom-types/base-response'
import { JobPost } from '@custom-types/job-post'

export interface GetJobListingAPIResponse {
  metaData: Metadata
  records: JobPost[]
}

export const getKey =
  (companyId: string, query: Record<PropertyKey, Stringifiable>) =>
  (pageIndex: number, previousPageData?: GetJobListingAPIResponse) => {
    if (!companyId) return null
    if (previousPageData && previousPageData?.records?.length === 0) return null // reached the end

    return JOB_LISTING_URL(companyId, {
      ...query,
      page: pageIndex + 1,
    })
  }

export function useJobListingQuery({
  companyId,
  swrInfiniteConfig,
  query,
}: {
  companyId: string
  swrInfiniteConfig?: SWRInfiniteConfiguration
  query: Record<PropertyKey, Stringifiable>
}) {
  const jobListingQueryResult = useSWRInfinite<GetJobListingAPIResponse>(
    getKey(companyId, {
      ...query,
    }),
    customFetcher,
    swrInfiniteConfig
  )

  return jobListingQueryResult
}

export default useJobListingQuery
